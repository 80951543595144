<template>
  <base-modal
    modal-title="Asset Details"
    popup-class="modal-xl"
  >
    <template #modalBody>
      <div
        v-if="canDownload"
        class="flex justify-evenly mt-2"
      >
        <div v-if="['image','pdf'].includes(__type)">
          <div>
            <div class="px-1 text-center text-sm font-semibold">
              128px (smallest)
            </div>
          </div>
          <div class="text-center">
            <base-button
              class="mt-2 mb-2"
              variant="btn-green"
              text="Thumbnail"
              :is-loading="isAssetDownloadingUrlLoading"
              @click="handleAssetDownloadViaUrl(assetDetails.renditions.transformations.thumbnail)"
            />
          </div>
        </div>

        <div v-if="['image','pdf'].includes(__type)">
          <div>
            <div class="px-1 text-center text-sm font-semibold">
              512px
            </div>
          </div>
          <div class="text-center">
            <base-button
              class="mt-2 mb-2"
              variant="btn-green"
              text="Preview"
              :is-loading="isAssetDownloadingUrlLoading"
              @click="handleAssetDownloadViaUrl(assetDetails.renditions.transformations.preview)"
            />
          </div>
        </div>

        <div v-if="['image','pdf'].includes(__type)">
          <div>
            <div class="px-1 text-center text-sm font-semibold">
              1500px
            </div>
          </div>
          <div class="text-center">
            <base-button
              class="mt-2 mb-2"
              variant="btn-green"
              text="Web"
              :is-loading="isAssetDownloadingUrlLoading"
              @click="handleAssetDownloadViaUrl(assetDetails.renditions.transformations.web)"
            />
          </div>
        </div>

        <div v-if="['image','pdf'].includes(__type)">
          <div>
            <div class="px-1 text-center text-sm font-semibold">
              300 dpi
            </div>
          </div>
          <div class="text-center">
            <base-button
              class="mt-2 mb-2"
              variant="btn-green"
              text="Half Scale"
              :is-loading="isAssetDownloadingUrlLoading"
              @click="handleAssetDownloadViaUrl(assetDetails.renditions.transformations.halfScale)"
            />
          </div>
        </div>

        <div v-if="['pdf'].includes(__type)">
          <div>
            <div class="px-1 text-center text-sm font-semibold">
              PDF
            </div>
          </div>
          <div class="text-center">
            <base-button
              class="mt-2 mb-2"
              variant="btn-green"
              :is-loading="isAssetDownloadingUrlLoading"
              text="View PDF"
              @click="handleAssetDownloadViaUrl(assetDetails.renditions.transformations.pdf, assetDetails)"
            />
          </div>
        </div>

        <div>
          <div>
            <div class="px-1 text-center text-sm font-semibold">
              Hi Res (Largest)
            </div>
          </div>
          <div class="text-center">
            <base-button
              class="mt-2 mb-2"
              variant="btn-green"
              :is-loading="isDownloadingAssetLoading"
              :text="`Original${printFileSize(assetDetails.fileSize)?' ( '+printFileSize(assetDetails.fileSize)+' )':''}`"
              @click="handleAssetDownload(assetDetails)"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col pr-4 items-center">
        <div class="w-full m-2 p-2 bg-custom-gray-6 rounded-sm">
          <div
            v-if="assetDetails.name"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Asset Name:</span>
            <span>{{ assetDetails.name }}</span>
          </div>

          <div
            v-if="assetDetails.styleGuideTitle"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Style Guide Title:</span>
            <span>{{ assetDetails.styleGuideTitle }}</span>
          </div>

          <div
            v-if="assetDetails.styleGuideTitle && __sgGroup"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Reference SG Group:</span>
            <span>{{ __sgGroup }}</span>
          </div>

          <div
            v-if="assetDetails.iteration"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Iteration:</span>
            <span>{{ assetDetails.iteration }}</span>
          </div>

          <div
            v-if="assetDetails.characterName"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Character Name:</span>
            <span>{{ assetDetails.characterName }}</span>
          </div>

          <div
            v-if="assetDetails.characterAction"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Character Action:</span>
            <span>{{ assetDetails.characterAction }}</span>
          </div>

          <div
            v-if="assetDetails.artworkType"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Artwork Type:</span>
            <span>{{ assetDetails.artworkType }}</span>
          </div>

          <div
            v-if="assetDetails.descriptiveKeywords"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Descriptive Keywords:</span>
            <span>{{ assetDetails.descriptiveKeywords }}</span>
          </div>

          <div
            v-if="assetDetails.assetReference"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Asset Reference:</span>
            <span>{{ assetDetails.assetReference }}</span>
          </div>

          <div
            v-if="assetDetails.imageColoration"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Image Coloration:</span>
            <span>{{ assetDetails.imageColoration }}</span>
          </div>

          <div
            v-if="assetDetails.actorLikness"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Actor Likness:</span>
            <span>{{ assetDetails.actorLikness }}</span>
          </div>

          <div
            v-if="assetDetails.pencilers"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Pencilers:</span>
            <span>{{ assetDetails.pencilers }}</span>
          </div>

          <div
            v-if="assetDetails.inkers"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Inkers:</span>
            <span>{{ assetDetails.inkers }}</span>
          </div>

          <div
            v-if="assetDetails.painters"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Painters:</span>
            <span>{{ assetDetails.painters }}</span>
          </div>

          <div
            v-if="assetDetails.colorists"
            class="flex flex-wrap my-2 text-sm"
          >
            <span class="font-semibold mx-4 w-44">Colorists:</span>
            <span>{{ assetDetails.colorists }}</span>
          </div>
        </div>

        <div class="inline-flex items-center justify-center m-2 relative overflow-hidden border border-solid border-custom-gray-8">
          <img
            v-if="['image','audio','other','pdf','zip'].includes(__type)"
            :src="__thumbnail"
          >
          <video
            v-else-if="['video'].includes(__type)"
            id="theVideo"
            autoplay
            width="840"
            :src="__thumbnail"
          />

          <div
            v-if="__wmEnabled"
            class="absolute inset-0 text-center"
          >
            <p
              v-for="col1 in 50"
              :key="col1"
              class="truncate my-px watermark2"
            >
              <span
                v-for="col in 3"
                :key="col"
                class="px-2"
              >
                {{ currentUser.name }} - {{ formatDate(Date(), 'MM/DD/YYYY HH:MM') }}
              </span>
            </p>
          </div>
        </div>

        <div
          v-if="['video'].includes(__type)"
          class="w-full h-8 mb-4 flex justify-center"
        >
          <base-button
            class="mr-3 py-1"
            variant="btn-primary"
            :text="videoBtnTitle"
            @click="playPause()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent, computed, ref } from 'vue';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { SUCCESS, ERROR, ATTACHEMENT_DOWNLOADED_SUCCESSFULLY, ATTACHEMENT_DOWNLOAD_FAILED } from '@/constants/alerts.js';
import { useStore } from 'vuex';
import { formatDate, printFileSize } from '@/helpers/util';

export default {
    name: 'StyleGuideAssetDetailsModal',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    props: {
        assetDetails: {
            type: Object,
            required: true
        }
    },

    setup (props) {
        const { notificationsStack } = useToastNotifications();
        const store = useStore();

        const canDownload = computed(() => props.assetDetails.canDownload);

        const isAssetDownloadingUrlLoading = ref(false);
        const handleAssetDownloadViaUrl = async (url, assetDetails) => {
            if (isAssetDownloadingUrlLoading.value) return;
            try {

                if ( assetDetails && (assetDetails.wmFlag||'').toLowerCase().startsWith('y') ) url = `${url}&skipWatermark=true`

                isAssetDownloadingUrlLoading.value = true;
                const resp = await store.dispatch('upload/getDownloadAttachmentURLViaUrl', {
                    params: {
                        url
                    }
                });

                if (!resp?.presignedUrl) throw new Error('Download URL is not available.');
                const windowOpened = window.open(resp.presignedUrl);
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ATTACHEMENT_DOWNLOADED_SUCCESSFULLY
                    });
                }
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ATTACHEMENT_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                isAssetDownloadingUrlLoading.value = false;
            }
        };

        const isDownloadingAssetLoading = ref(false);
        const handleAssetDownload = async (assetDetails) => {
            if (isDownloadingAssetLoading.value) return;
            try {
                isDownloadingAssetLoading.value = true;

                let getPresignedURL
                {
                  let url = assetDetails.asgardMasterBaseUrl
                  if (!url) throw `Master Download URL is not available`

                  const masterData = {
                    fileId: assetDetails.styleGuideFileId,
                    objectId: assetDetails.styleGuideAssetId,
                    filename: assetDetails.name,
                    licenseeIds: (store.getters['auth/getUserLicensees'] || []).map(l => l.licenseeId),
                    downloadType: 'Asset Gallery'
                  }
                  url += `?masterData=${encodeURIComponent(window.btoa(JSON.stringify(masterData)))}`

                  if ( (assetDetails.wmFlag||'').toLowerCase().startsWith('y') ) url = `${url}&skipWatermark=true`

                  getPresignedURL = await store.dispatch('upload/getDownloadAttachmentURLViaUrl', { params: { url } })
                }

                if (!getPresignedURL || !getPresignedURL?.presignedURL) throw new Error('Download URL is not available.');
                const windowOpened = window.open(getPresignedURL?.presignedURL, '_blank');
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ATTACHEMENT_DOWNLOADED_SUCCESSFULLY
                    });
                }
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ATTACHEMENT_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                isDownloadingAssetLoading.value = false;
            }
        };

        const currentUser = computed(() => store.getters['auth/getUserInfo']);

        const __wmEnabled = ref(false);

        const __type = computed(() => {
            if (props.assetDetails?.name && props.assetDetails.renditions.original) {
                const ext = props.assetDetails?.name.toLowerCase().split('.').pop();
                if (ext) {
                // AUDIO
                    if (['wav','mp3','aif','aiff'].includes(ext)) return 'audio';
                    // VIDEO
                    else if (['mov','mp4','m4v','avi'].includes(ext)) return 'video';
                    // PDF
                    else if (['pdf'].includes(ext)) return 'pdf';
                    // ZIP
                    else if (['zip'].includes(ext)) return 'zip';
                    // IMAGE
                    else return 'image';
                }
            }
            return 'other';
        });

        const __thumbnail = computed(() =>{
            let tmpThumbnail = require('@/assets/images/upload/asset.png');
            __wmEnabled.value = false;

            if (props.assetDetails?.name && props.assetDetails.renditions.original) {
                const __rends = props.assetDetails.renditions.original
                const ext = props.assetDetails?.name.toLowerCase().split('.').pop();
                if (ext) {
                    // AUDIO
                    if (['wav','mp3', 'aif', 'aiff'].includes(ext)) {
                        tmpThumbnail = require('@/assets/images/upload/audio.png');
                    } else if (['mov','mp4','m4v','avi'].includes(ext)) {
                    // VIDEO
                        tmpThumbnail = require('@/assets/images/upload/video.png');
                        if (__rends.videoProxy) {
                            tmpThumbnail = __rends.videoProxy;
                            __wmEnabled.value = true
                        }
                    } else if (['pdf'].includes(ext)) {
                    // PDF
                        tmpThumbnail = require('@/assets/images/upload/pdf-small.png');
                        if (__rends.imagePreview && !__rends.imagePreview.includes('/700x700')) {
                            tmpThumbnail = __rends.imagePreview;
                            __wmEnabled.value = true;
                        }
                    } else {
                    // IMAGE
                        if (__rends.imagePreview && !__rends.imagePreview.includes('/700x700')) {
                            tmpThumbnail = __rends.imagePreview;
                            __wmEnabled.value = true;
                        }
                    }
                }
            }
            return tmpThumbnail;
        });

        const videoBtnTitle = ref('Pause');
        const playPause = () => {
            const myVideo = document.getElementById('theVideo');
            if (myVideo.paused) {
                myVideo.play();
                videoBtnTitle.value = 'Pause';
            } else {
                myVideo.pause();
                videoBtnTitle.value = 'Play';
            }
        };

        const __sgGroup = computed(() => {
            const tmp = store.getters['styleGuideTree/getStyleGuideGroups'];
            return tmp[props.assetDetails.styleGuideTitle];
        });

        return {
            handleAssetDownload,
            handleAssetDownloadViaUrl,
            isDownloadingAssetLoading,
            isAssetDownloadingUrlLoading,
            currentUser,
            formatDate,
            printFileSize,
            __type,
            __wmEnabled,
            __thumbnail,
            __sgGroup,
            playPause,
            videoBtnTitle,
            canDownload
        };
    }
};
</script>

<style>
.watermark2 {
  font-weight: 600;
  color: rgba(128,128,128,.45);
}
</style>
